<script>
	import Icon from 'mdi-svelte';
        import { mdiShieldLock, mdiMenu, mdiTimer, mdiTrafficCone, mdiConnection, mdiDownload, mdiWeatherNight, mdiWeatherSunny, mdiShieldAlert, mdiAndroid, mdiApple, mdiMicrosoftWindows, mdiGithub, mdiLock, mdiDelete, mdiHours24, mdiFire} from '@mdi/js';
	import { Dialog, Chip, Avatar, TextField, Button, MaterialApp, AppBar, Tooltip, ListItem, NavigationDrawer, List, Overlay } from 'svelte-materialify';
	var result = {"code": false};
	var vpndata = {"code": false};
	
	let active = false; // 메뉴바 여부
	let whitedark = "dark"; // 텍스트, 백그라운드 다크모드
	let whiteblack = "white" // icon 다크모드 
	let darkMode = true; // 다크모드 여부
	let show = false; // 메뉴바 점검 튤립
	let connect_modal; // 서버연결 모달
	let vpnkey_modal; // 서버키 발급 모달
	let install_modal; // 클라이언트 설치 모달
	let limit_modal; // 리미트 모달

	let apiurl = "https://grvpnapi.phbot.kr";
	let github_url = "https://github.com/kimjunsung04";
	let windows_url = "https://raw.githubusercontent.com/Jigsaw-Code/outline-releases/master/client/stable/Outline-Client.exe";
	let android_url = "https://play.google.com/store/apps/details?id=org.outline.android.client";
	let apple_url = "https://itunes.apple.com/us/app/outline-app/id1356177741";
	let mac_url = "https://apps.apple.com/us/app/outline-app/id1356178125"

	let password = ""
	const hash = s =>
		s.split('').reduce((a, b) => {
		a = (a << 5) - a + b.charCodeAt(0)
		return a & a
	}, 0)

	function github_connect() {
		window.open(github_url, '_blank');
	}

	function windows_install() {
		window.open(windows_url, '_blank');
	}

	function android_install() {
		window.open(android_url, '_blank');
	}

	function apple_install() {
		window.open(apple_url, '_blank');
	}

	function mac_install() {
		window.open(mac_url, '_blank');
	}

	function close() {
		active = false;
	}
	function open() {
		active = true;
	}
	function krkeygen() {
		keygen(result.krdata.name);
	}
	function jpkeygen() {
		keygen(result.jpdata.name);
	}

	function toggle() {
        darkMode = !darkMode;
		if(darkMode == true) {
			whitedark = "dark";
			whiteblack = "white";
		}
		else {
			whitedark = "white";
			whiteblack = "black";
		}
    }


	async function pageload() {
		const res = await fetch(apiurl, {method: 'POST'})
			.then((response) => response.json());
		result = res;
	}

	async function keygen(code) {
		connect_modal = false;
		const res = await fetch(`${apiurl}/genkey?${code}`, {method: 'POST'})
			.then((response) => response.json());
		vpndata = res;
		if(vpndata.code == "ok"){
			vpnkey_modal = true;
		}
		else if(vpndata.code == "limit"){
			limit_modal = true;
		}
		pageload();
	}

	function copy_to_clipboard() {
		var copyText = document.getElementById("vpnurl");
		copyText.select();
		document.execCommand("Copy");
		return copyText.value;
	}

	function vpn_connect() {
		copy_to_clipboard();
		window.open(vpndata.vpnurl, '_blank');
	}

	pageload();
</script>
<main>
	<MaterialApp>
		{#if hash(password) === 215800925}
			<AppBar class="elevation-2 theme--{whitedark}">
				<div slot="icon">
					{#if result.code == "ok" } <!-- 메뉴버튼 점검시 비활성화처리 -->
						{#if result.inspectiondata['ck'] }
							<Tooltip top bind:active={show}>
								<Button fab depressed on:click={open} disabled>
									<Icon path={mdiMenu} />
								</Button>
								<span class="red-text" slot="tip"><b>점검중에는 이용이 불가능합니다!</b></span>
							</Tooltip>
						{:else}
							<Button fab depressed on:click={open}>
								<Icon path={mdiMenu} />
							</Button>
						{/if}
					{/if}
				</div>
				<span slot="title">GRVPN</span>
				<div style="flex-grow:1" />
				{#if darkMode }
					<Button fab size="small" on:click={toggle}>
						<Icon path={mdiWeatherSunny} />
					</Button>
				{:else}
					<Button fab size="small" on:click={toggle}>
						<Icon path={mdiWeatherNight} />
					</Button>
				{/if}
			</AppBar>
			<div class="d-flex align-center justify-center theme--{whitedark}">
				<Dialog class="pa-4 text-center" bind:active={vpnkey_modal}>
					<div class="pl-4 pr-4 pt-3">
						<h5>연결하기</h5>
						<br>
						<TextField id="vpnurl" value={vpndata.vpnurl}>VPN 키</TextField>
						<Button class="primary-color" style="min-width: 85%" on:click={vpn_connect}>연결하기</Button>
					</div>
				</Dialog>

				<Dialog class="pa-4 text-center" bind:active={limit_modal}>
					<div class="pl-4 pr-4 pt-3">
						<h5><Icon path={mdiTimer} />워워 진정하세요...!</h5>
						<br>
						<p>너무 빠르게 요청하고있어요! {vpndata.data}초 후 다시 시도해주세요!</p>
					</div>
				</Dialog>

				<Dialog class="pa-4 text-center" bind:active={install_modal}>
					<div class="pl-4 pr-4 pt-3">
						<h5>클라이언트를 설치해주세요!</h5>
						<br>
						<Button depressed on:click={windows_install} class="primary-color"><Icon path={mdiMicrosoftWindows} /> 윈도우</Button>
						<Button depressed on:click={android_install} class="green white-text"><Icon path={mdiAndroid} /> 안드로이드</Button>
						<Button depressed on:click={apple_install} class="red white-text"><Icon path={mdiApple} /> 애플</Button>
						<Button depressed on:click={mac_install} class="red white-text"><Icon path={mdiApple} /> 맥OS</Button>
					</div>
				</Dialog>

				<Dialog class="pa-4 text-center" bind:active={connect_modal}>
					<div class="pl-4 pr-4 pt-3">
						<h5>선호하는 서버위치를 선택해주세요!</h5>
						<br>
						{#if result.code == "ok" }
							{#if result.krdata['tf']}
								<Button on:click={krkeygen} class="primary-color" block><b>{result.krdata['name']}</b> 서버 <Chip class="ma-2" size="small">{result.krdata['keynum']}</Chip></Button><br>
							{:else}
								<Button block disabled><Icon path={mdiTrafficCone} /><b>{result.krdata['name']}</b> 서버</Button><br>
							{/if}
							{#if result.jpdata['tf']}
								<Button on:click={jpkeygen} class="red white-text" block><b>{result.jpdata['name']}</b> 서버 <Chip class="ma-2" size="small">{result.jpdata['keynum']}</Chip></Button>
							{:else}
								<Button block disabled><Icon path={mdiTrafficCone} /><b>{result.jpdata['name']}</b> 서버</Button>
							{/if}
						{/if}
						<br>
					</div>
				</Dialog>

				<div class="center text-center">
					<h2 class="text-h2">
						<Icon size=60px path={mdiShieldLock} color="{whiteblack}" /><b>GRVPN</b><Icon size=60px path={mdiShieldLock} color="{whiteblack}" />
					</h2>
					<p>
						언제 어디서나 쉽고 빠르게 무료로 이용가능한<br>
						경남로봇고등학교 익명 VPN 입니다!
					</p>
					{#if result.code == "ok" }
						{#if result.inspectiondata['ck'] } <!-- 메인페이지 점검중 코멘트 -->
							<p class="red-text"><b><Icon path={mdiTrafficCone} /> {result.inspectiondata['con']}</b></p>
						{/if}
					<!--
					{:else if result.code == "notin" } 학교밖에서 접근시 코멘트
						<p class="red-text"><b><Icon path={mdiShieldAlert} /> 경남로봇고등학교 내에서만 이용 가능합니다.</b></p> 
					-->
					{/if}
					<div>
						<Chip class="ma-2 orange white-text"><span><Icon path={mdiDelete} /><b>NO LOG</b></span></Chip><Chip class="ma-2 green white-text"><span><Icon path={mdiHours24} /><b>100% UP TIME</b></span></Chip><Chip class="ma-2 indigo white-text"><span><Icon path={mdiLock} /><b>Encryption</b></span></Chip>
						<br>
						{#if result.code  == "ok"}
							{#if result.krdata['tf']}
								{#if result.krdata['keynum'] >= 15} <!-- 인원 15명 넘어가면 이모지 삽입 -->
									<Chip class="ma-2 primary-color white-text">
										<Icon path={mdiFire} /><Avatar class="primary-color darken-2">{result.krdata['keynum']}</Avatar>
										<span><b>{result.krdata['name']}</b> 서버</span>
									</Chip>
								{:else}
									<Chip class="ma-2 primary-color white-text">
										<Avatar class="primary-color darken-2">{result.krdata['keynum']}</Avatar>
										<span><b>{result.krdata['name']}</b> 서버</span>
									</Chip>
								{/if}
							{:else}
								<Chip class="ma-2 white-text">
									<span><Icon path={mdiTrafficCone} /><b>{result.krdata['name']}</b> 서버</span>
								</Chip>
							{/if}
							{#if result.jpdata['tf']}
								{#if result.jpdata['keynum'] >= 15} <!-- 인원 15명 넘어가면 이모지 삽입 -->
									<Chip class="ma-2 red white-text">
										<Icon path={mdiFire} /><Avatar class="red darken-2">{result.jpdata['keynum']}</Avatar>
										<span><b>{result.jpdata['name']}</b> 서버</span>
									</Chip>
								{:else}
									<Chip class="ma-2 red white-text">
										<Avatar class="red darken-2">{result.jpdata['keynum']}</Avatar>
										<span><b>{result.jpdata['name']}</b> 서버</span>
									</Chip>
								{/if}
							{:else}
								<Chip class="ma-2 white-text">
									<span><Icon path={mdiTrafficCone} /><b>{result.jpdata['name']}</b> 서버</span>
								</Chip>
							{/if}
						{/if}
					</div>
				</div>
				<NavigationDrawer absolute {active}>
					<List>
						<br><br>
						<ListItem on:click={() => (connect_modal = true)}>
						<span slot="prepend">
							<Icon path={mdiConnection} />
						</span>
						서버 연결하기
						</ListItem>
						<ListItem on:click={() => (install_modal = true)}>
						<span slot="prepend">
							<Icon path={mdiDownload} />
						</span>
						클라이언트 다운로드
						</ListItem>
						<ListItem on:click={github_connect}>
							<span slot="prepend">
								<Icon path={mdiGithub} />
							</span>
							개발자
						</ListItem>
					</List>
				</NavigationDrawer>
				<Overlay index={1} {active} on:click={close} absolute />
			</div>
		{:else}
			<div class="d-flex align-center justify-center theme--{whitedark}">
				<div class="center text-center">
					<h2 class="text-h2">
						<Icon size=60px path={mdiShieldLock} color="{whiteblack}" /><b>GRVPN</b><Icon size=60px path={mdiShieldLock} color="{whiteblack}" />
					</h2>
					<h6>
						비밀번호를 입력해 주세요
						<br><br>
						<TextField id="vpnurl" bind:value={password} type="password" outlined>비밀번호</TextField>
					</h6>
				</div>
			</div>
		{/if}
	</MaterialApp>
</main>

<style>
	.center{
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
</style>
